/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://d56u22ccfnesnklotw3dkq3m54.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qpnep5aatrbf7dtjjqq445g6gu",
    "aws_cognito_identity_pool_id": "us-east-1:3387932f-c2c2-4aa5-9f69-eab00ca41b5e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FlSAWxs3B",
    "aws_user_pools_web_client_id": "3vgv91bbbrha7qgmeuasna11h3",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "blogapp-20220109195853-hostingbucket-mydev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d33fz0on62iftp.cloudfront.net",
    "aws_user_files_s3_bucket": "blogappmedia983242133183232-mydev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
